import React from "react"
import { navigate, Link } from "gatsby"
import {Form, Alert} from 'react-bootstrap'
import { handleLogin, isLoggedIn } from "../utils/auth"
import '../styles/login.css'

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      username: ``,
      password: ``,
      redirect:'/app/profile',
    }
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    handleLogin(this.state)
  }

  render() {

    const queryString = global.window?.location.search
    const urlParams = new URLSearchParams(queryString)

    const loginSuccess =  urlParams.get("success") || null
    const enrollId =  urlParams.get("enroll") || null
    const countryCode =  urlParams.get("code") || null

    if(enrollId && countryCode) {  
      if(this.state.redirect !== '/app/enroll/'+enrollId+'/?code='+countryCode) {
        this.setState({
          redirect: '/app/enroll/'+enrollId+'/?code='+countryCode,
        })
      } 
    }

    if (isLoggedIn()) {
      navigate(`/app/profile`)
    }

    return (
    <section>
        <div className="container">
            <div className="formWrapper">
                <div className="text-center mb-4">
                    <h3>Welcome to Crampete!</h3>
                </div>
                <Form method="post" id="loginForm" onSubmit={event => { this.handleSubmit(event) }} >
                {loginSuccess && <Alert variant="danger" style={{ fontSize: "14px", textAlign: "center" }}> Username or password is incorrect </Alert>}
                    <Form.Group controlId="loginEmail">
                    <Form.Control onChange={e =>this.handleUpdate(e)} required type="email" name="username" placeholder="Email Address" />
                    </Form.Group>
                    <Form.Group controlId="loginPassword">
                    <Form.Control onChange={e =>this.handleUpdate(e)} required type="password" name="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="text-center">
                        <Form.Control type="submit" value="Log In" name="submit" className="btn btn-primary" />
                    </Form.Group>
                </Form>
                <div className="text-center">
                    Dont have an account? <Link to="/app/signup">Sign up</Link>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default Login