import React from 'react';
import { Modal } from 'react-bootstrap';
import StripeCardElement from './StripeCardElement';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

// let stripePromise;

// const loadStripePromise = async () => {
//   stripePromise = await loadStripe('pk_test_51H2C39D2pWkJj88NLoGQslRL8ATo9wm2zLJERi8FoIvBEswAgzWC1EHjhY0D4d49dz5CItjFxHu3n65mRhWmXiqe00XVtnCL4e');
// };
// loadStripePromise();

// TestKey
// const stripePromise = loadStripe("pk_test_51H2C39D2pWkJj88NLoGQslRL8ATo9wm2zLJERi8FoIvBEswAgzWC1EHjhY0D4d49dz5CItjFxHu3n65mRhWmXiqe00XVtnCL4e");

// Production Key
const stripePromise = loadStripe("pk_live_FTm9tlGxbCkiy2A5oYpKGTx500S1j1UVoC");

const StripeModal = props => {

  const {success, setSuccess, setShowStripe, stripeBatchDetails, showEmi, sbatchName, sbatchCurrency, sbatchAmount } = props;

  return(
    <Modal
      id="stripe-form"
      show={true}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Elements stripe={stripePromise} >
          <StripeCardElement success={success} setSuccess={setSuccess} setShowStripe={setShowStripe} stripeBatchDetails={stripeBatchDetails} showEmi={showEmi} sbatchName={sbatchName} sbatchCurrency={sbatchCurrency} sbatchAmount={sbatchAmount} />
        </Elements>

      </Modal.Body>
    </Modal>
  )
}

export default StripeModal