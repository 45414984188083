import React, {useState, useEffect} from 'react';
import '../styles/enroll.css';
import { getCurrentUser } from "../utils/auth";
import Thankyou from '../components/Thankyou';
import Loader from '../components/Loader';
import EmiModal from '../components/EmiModal';
import StripeModal from '../components/StripeModal';
import { navigate } from 'gatsby';
import {loadScript} from '../utils/Helper';

const Enroll = () => {

    const queryString = global.window?.location.search
    const urlParams = new URLSearchParams(queryString);
    const branchCode =  urlParams.get("code") || null
    if(!branchCode) {
        navigate('/404');
    }

    const [loaderHide, setLoaderhide] = useState(false);
    
    const [ showEmi, setShowEmi ] = useState({
        show: false,
        selectedEMI: null
    });

    const { token, LoginId, StudentName } = getCurrentUser();

    const pathArray = window.location.pathname.split('/');
    const batchId = pathArray[3];
    const opts = { BatchId: batchId }
    
    const [batch , setBatch] = useState(null);

    let query = Object.keys(opts)
         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(opts[k]))
         .join('&');

        useEffect( () => {
            fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/get-batch?'+query, {
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
            }).then(function(response) {
                return response.json();
            }).then(function(res) {
                if (res.success) {
                    setBatch(res.data.Item)
                    setLoaderhide(true)
                }
            }); 
        }, []
        )

    const [ success, setSuccess ] = useState({
        show: false,
        txnId: null
    })

    // Handle Stripe payment

    const [showStripe, setShowStripe] = useState(false)
    
    const handleStripe = () => {
        setShowStripe(!showStripe)
    }

    const stripeBatchDetails = {
            BatchId: batchId,
            BranchId: branchCode,
            IsFirstEMI: (showEmi.selectedEMI ? true : false ),
            IsEMI: (showEmi.selectedEMI ? true : false ),
            InstallmentId: (showEmi.selectedEMI ? showEmi.selectedEMI : '')
    }

    // Handle Razorpay payment    

    async function displayRazorpay (data) {

        try {
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if(!res) {
            alert('Razorpay SDK failed to load');
            return
        }

        const liveKey='rzp_live_6PTIN3YzEqsjWH';
        // const testKey='rzp_test_uGoq5ABJztRAhk';

        const options = {
            key: liveKey,
			currency: data.currency,
			amount: data.amount,
			order_id: data.orderId,
			name: batch && batch.Courses.Title,
			description: batch && batch.Name,
            image: "https://www.crampete.com/images/logo.svg",
            handler: function (response){

                const opts = {
                    TxnId: data.txnId,
                    PaymentId: response.razorpay_payment_id,
                    OrderId: response.razorpay_order_id,
                    IsFirstEMI: (showEmi.selectedEMI ? true : false ),
                    IsEMI: (showEmi.selectedEMI ? true : false),
                    InstallmentId: (showEmi.selectedEMI ? showEmi.selectedEMI : '')
                }
            
                fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token
                },
                body: JSON.stringify(opts)
                }).then(function(response) {
                return response.json();
                }).then(function(res) {
                    if(res.success) {
                        setSuccess({
                            show: true,
                            txnId: res.data.txnId
                        })
                    }
                });
            },
            prefill: {
                name: StudentName,
                email: LoginId,
            },
        };
        const paymentObject = new window.Razorpay(options);
        setLoaderhide(true);
        paymentObject.open();    
        } catch (error) {
            console.error(error);
        }
    }

    const handlePay = () => {

        setLoaderhide(false);
        
        const opts = {
            BatchId: batchId,
            BranchId: branchCode,
            IsFirstEMI: (showEmi.selectedEMI ? true : false ),
            IsEMI: (showEmi.selectedEMI ? true : false ),
            InstallmentId: (showEmi.selectedEMI ? showEmi.selectedEMI : '')
        }

        let query = Object.keys(opts)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(opts[k]))
             .join('&');

            fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll?'+query, {
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
            }).then(function(response) {
            return response.json();
            }).then(function(res) {
                if(res.success) {
                    return displayRazorpay(res.data)
                }
            }).catch((err) => {
                console.error(err);
            });

    }

    const handleEmi = () => {

        setShowEmi({
            show:true,
            selectedEMI:null
        })
    }

    let selectedInstallment = showEmi.selectedEMI && batch.Installment.filter( (data) => {
        return(data.Id == showEmi.selectedEMI)
        })
    
    let batchActualPrice = batch && batch.Branches.filter(branch => branch.BranchId === branchCode)[0].Price;
    let batchDiscountedPrice = batch && batch.Branches.filter(branch => branch.BranchId === branchCode)[0].DiscountedPrice;
    let currency = batch && batch.Branches.filter(branch => branch.BranchId === branchCode)[0].PaymentType;
    let batchPrice = selectedInstallment && parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)
    let emiPerMonth = selectedInstallment && Math.round((((parseInt(selectedInstallment[0].InterestRate)/100)*parseInt(batchPrice)) + parseInt(batchPrice))/parseInt(selectedInstallment[0].Terms));
    let totalAmount = selectedInstallment && emiPerMonth*parseInt(selectedInstallment[0].Terms);

    return (
            <section>
                <Loader loaderHide={loaderHide} />
                <div className="container">
                    <div className="enroll-wrapper">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="left-wrapper">
                                    <div className="enrollmentDetails">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={batch && batch.ThumbnailURL ? batch.ThumbnailURL : 'https://placeimg.com/200/100/any' } />
                                            </div>
                                            <div className="col-md-8">
                                                <p>{batch && batch.Name}</p>
                                                <small className="text-muted">Courses: {batch && batch.Courses.map( item => { return item.Title }).join(', ') }</small>
                                                <small className="text-muted">{batch && `Duration: ${batch.Duration}`}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="benefits-wrapper">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper">
                                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/checked.png"/>
                                                </div>
                                                <div className="benefit-wrapper">
                                                    <p>Lifetime Access to course materials</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper">
                                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/checked.png"/>
                                                </div>
                                                <div className="benefit-wrapper">
                                                    <p>Mentorship and Intership Assistance</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper">
                                                    <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/checked.png"/>
                                                </div>
                                                <div className="benefit-wrapper">
                                                    <p>Certification from Crampete</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="right-wrapper">
                                    <div className="order-card">
                                        <div className="pb-3 font-weight-bold">Billing Summary</div>
                                        <table className="table">
                                            <tbody className="crampete-checkout">
                                                <tr>
                                                    <td align="left">Price</td>
                                                    <td align="right" className="font-weight-bold">{`${currency} `}{batchActualPrice}</td>
                                                </tr>
                                                <tr>
                                                    <td className="color-green" align="left">Discount</td>
                                                    <td align="right" className="color-green font-weight-bold">- {`${currency} `}{batchDiscountedPrice}</td>
                                                </tr>
                                                {selectedInstallment &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td className="color-green" align="left">Rate of Interest</td>
                                                        <td align="right" className="color-green font-weight-bold">{selectedInstallment[0].InterestRate}%</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="color-green" align="left">EMI Per Month</td>
                                                        <td align="right" className="color-green font-weight-bold">{`${currency} `}{emiPerMonth}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="color-green" align="left">Total Amount</td>
                                                        <td align="right" className="color-green font-weight-bold">{`${currency} `}{emiPerMonth} x {selectedInstallment[0].Terms}m = {totalAmount}</td>
                                                    </tr>
                                                    </React.Fragment>
                                                }
                                            </tbody>
                                        </table>
                                        {selectedInstallment ?  
                                        <div className="amount-payable d-flex">
                                            <div style={{fontWeight:400}}>Due Now</div>
                                            <div className="font-weight-bold">{`${currency} `}{emiPerMonth}</div>
                                        </div>
                                        :
                                        <div className="amount-payable d-flex">
                                            <div style={{fontWeight:400}}>Amount to be paid</div>
                                            <div className="font-weight-bold">{`${currency} `}{parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)}</div>
                                        </div> }
                                        
                                        <div className="payment-methods">
                                            {batch && (batch.Installment.length > 0 ? <small className="text-muted">Check available <a href="javascript:void(0);" onClick={handleEmi}>EMI Options</a></small> : '' )}
                                            <div className="discount">
                                                <form>
                                                    <input type="text" required placeholder="Have a discount code?" />
                                                    <input type="submit" value="Apply" className="btn btn-primary" />
                                                </form>
                                            </div>
                                            <small>By clicking on pay you agree to our <a href="/terms-and-conditions" target="_blank">terms &amp; conditions</a></small>
                                            <div className="mt-5 text-center">                            
                                            {currency !== 'INR' ?
                                            <div>
                                                <div className="text-left mb-3 font-weight-bold">Card details</div>
                                                <button id="stripe-modal" className="btn w-100 btn-primary" onClick={handleStripe}>Pay {`${currency} `}{ selectedInstallment ? emiPerMonth : parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)}</button>
                                            </div> : 
                                            <button id="razorpay-cta" className="btn w-100 btn-primary" onClick={handlePay}>Pay {`${currency} `}{ selectedInstallment ? emiPerMonth : batch && parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)}</button>}

                                                <p className="mt-3"><small className="text-muted">Secure Checkout</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showStripe && <StripeModal success={success} setSuccess={setSuccess} setShowStripe={setShowStripe} showEmi={showEmi} sbatchName={batch && batch.Name} sbatchCurrency={currency} sbatchAmount={selectedInstallment ? emiPerMonth : batch && parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)} stripeBatchDetails={stripeBatchDetails} />}
                {showEmi.show && <EmiModal showEmi={showEmi} currency={currency} setShowEmi={setShowEmi} batchPrice={parseInt(batchActualPrice) - parseInt(batchDiscountedPrice)} emiDetails={batch.Installment} />}
                { success.show && <Thankyou txnId={success.txnId} batch={batch && batch.Name} /> }
            </section>
    )
}

export default Enroll