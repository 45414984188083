import React, { useState } from "react"
import { Button, Modal, Form, Alert } from "react-bootstrap"
import '../styles/emi-modal.css'

const EmiModal = props => {

  const { setShowEmi, emiDetails, batchPrice, showEmi, currency } = props

  const [showAlert, setShowAlert] = useState(false)

const handleClose = () => {
  setShowEmi((prevState) => {
    return { ...prevState, show: false, selectedEMI:null };
  });
}

const handleRadioChange = (e) => {

  if(e.target.value) {
    setShowAlert(false)
    setShowEmi(
      {...showEmi, selectedEMI: e.target.value }
    )
  }

}

const handleEMI = () => {

  if(!showEmi.selectedEMI) {
    setShowAlert(true)
  } else {
    setShowEmi((prevState) => {
      return { ...prevState, show: false };
    });
  }
    
} 

    return (
        <Modal
      id="emi-form"
      show={true}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Button className="close" onClick={handleClose}>
        <span aria-hidden="true">×</span>
      </Button>
      <Modal.Body>
      <div>
        <div className="text-center">
          <p className="emi-plans font-weight-bold">Available EMI Plans</p>
        </div>
        <Form>
        {showAlert && <Alert variant="danger" className="text-center">Please select an EMI option</Alert>}
          <table className="table table-bordered">
            <tr>
              <th>Select</th>
              <th>EMI Plan</th>
              <th>Interest (%)</th>
              <th>Total Cost</th>
            </tr>
            {emiDetails && emiDetails.map(emi => {

              let emiPerMonth = Math.round((((parseInt(emi.InterestRate)/100)*parseInt(batchPrice)) + parseInt(batchPrice))/parseInt(emi.Terms));

              return (
                <tr>
                  <td><Form.Check type="radio" value={emi.Id} name="emiRadio" onChange={handleRadioChange} /></td>
                  <td><b>{`${currency} `}{emiPerMonth} x {emi.Terms}m</b></td>
                  <td>{emi.InterestRate}%</td>
                  <td>{`${currency} `}{emiPerMonth * parseInt(emi.Terms)}</td>
                </tr>    
              )

            })}
          </table>
        </Form>
            <ul>
              <li><button className="cancel" onClick={handleClose}>Cancel</button></li>
              <li><button className="btn btn-primary" onClick={handleEMI}>Proceed with EMI</button></li>
            </ul>
        </div>
      </Modal.Body>
    </Modal>
    )
}

export default EmiModal