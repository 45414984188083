import React, {useState, useEffect} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Form } from 'react-bootstrap';
import '../styles/StripeCardElement.css';
import { getCurrentUser } from "../utils/auth";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const StripeCardElement = props => {

  const { token, LoginId, StudentName } = getCurrentUser();

  const [error, setError] = useState({
    errorMessage: null
  })

  const { success, setSuccess, setShowStripe, stripeBatchDetails, showEmi, sbatchName, sbatchCurrency, sbatchAmount } = props;
  
  const stripe = useStripe();
  const elements = useElements();
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    document.getElementById("stripe-cta").setAttribute("disabled",true);
    document.getElementById("stripe-cta").textContent = "Please wait...";
  
      let clientSecret;
      let transactionId;
  
      let query = Object.keys(stripeBatchDetails)
               .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(stripeBatchDetails[k]))
               .join('&');
  
      await fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll/stripe?'+query, {
      method: 'get',
      headers: {
          "Content-Type": "application/json",
          "Authorization": token
      }
      }).then(function(response) {
      return response.json();
      }).then(function(res) {
          clientSecret = res.data.token;
          transactionId = res.data.txnId;
      });
  
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: StudentName,
            address: {
              city: 'Chennai',
              country: 'SG',
              line1:'132, Habibullah Rd',
              state:'Tamil Nadu'
            }
          },
        }
      });
  
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setError({
          errorMessage: result.error.message
        })
  
        document.getElementById("stripe-cta").textContent = `Pay ${sbatchCurrency} ${sbatchAmount}`;
        document.getElementById("stripe-cta").removeAttribute("disabled");
  
      } else {
        // The payment has been processed!
        document.getElementById("stripe-cta").textContent = `Pay ${sbatchCurrency} ${sbatchAmount}`;
        document.getElementById("stripe-cta").removeAttribute("disabled");
  
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          
          const opts = {
            TxnId: transactionId,
            PaymentId: result.paymentIntent.id,
            IsFirstEMI: (showEmi.selectedEMI ? true : false ),
            IsEMI: (showEmi.selectedEMI ? true : false),
            InstallmentId: (showEmi.selectedEMI ? showEmi.selectedEMI : '')
          }
          
          fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll/stripe', {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(opts)
            }).then(function(response) {
            return response.json();
            }).then(function(res) {
                if(res.success) {
                  
                  setSuccess((prevState) => {
                    return { ...prevState, show: true, txnId: res.data.txnId };
                  });

                  // setShowStripe((prevState) => {
                  //   return { ...prevState, stripeShow: false };
                  // });
                  
                }
            });
        }
      }
  }

  useEffect(() => {
    if(success.show) {
      setShowStripe(false);
    }
  },[success.show])

  return (
    <React.Fragment>
      <div className="mb-3 text-center font-weight-bold">Billing Information</div>
    <form id="stripe-form" onSubmit={handleSubmit}>
      <Form.Group controlId="stripeForm.Name">
        <Form.Control type="text" required value={StudentName ? StudentName : ''} name="name" placeholder="Name" />
      </Form.Group>
      <Form.Group controlId="stripeForm.Email">
        <Form.Control type="email" required name="email" value={LoginId ? LoginId : ''} placeholder="Email" />
      </Form.Group>
      <Form.Group controlId="stripeForm.Address">
        <Form.Control type="address" required name="address" placeholder="Address" />
      </Form.Group>
      <Form.Group controlId="stripeForm.City">
        <Form.Control type="text" required name="city" placeholder="City" />
      </Form.Group>
      <div className="d-flex justify-content-between">
        <Form.Group controlId="stripeForm.State">
          <Form.Control type="text" required name="state" placeholder="State" />
        </Form.Group>
        <Form.Group controlId="stripeForm.Zip">
          <Form.Control type="number" required name="zip" placeholder="Zip" />
        </Form.Group>
      </div>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Control as="select" required name="country">
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="BE">Belgium</option>
          <option value="BR">Brazil</option>
          <option value="CA">Canada</option>
          <option value="CN">China</option>
          <option value="DK">Denmark</option>
          <option value="FI">Finland</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
          <option value="HK">Hong Kong</option>
          <option value="IE">Ireland</option>
          <option value="IT">Italy</option>
          <option value="JP">Japan</option>
          <option value="LU">Luxembourg</option>
          <option value="MY">Malaysia</option>
          <option value="MX">Mexico</option>
          <option value="NL">Netherlands</option>
          <option value="NZ">New Zealand</option>
          <option value="NO">Norway</option>
          <option value="PT">Portugal</option>
          <option value="SG" selected>Singapore</option>
          <option value="ES">Spain</option>
          <option value="SE">Sweden</option>
          <option value="CH">Switzerland</option>
          <option value="GB">United Kingdom</option>
          <option value="US">United States</option>
        </Form.Control>
      </Form.Group>
      <div className="mb-3 text-center font-weight-bold">Payment Information</div>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="stripe-error" role="alert">{error.errorMessage && error.errorMessage}</div>
      <button id="stripe-cta" className="btn w-100 btn-primary mt-2" disabled={!stripe}>{`Pay ${sbatchCurrency} ${sbatchAmount}`}</button>
    </form>
  </React.Fragment>
  );
};

export default StripeCardElement;