import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Profile from "../components/Profile"
import Login from "../components/Login"
import Signup from "../components/Signup"
import PrivateRoute from "../components/PrivateRoute"
import Enroll from "../components/Enroll"
const App = () => {

  return (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/enroll/:id" component={Enroll} />
      <Signup path="/app/signup" />
      <Login path="/app/login/" />
    </Router>
  </Layout>
)
  
}

export default App