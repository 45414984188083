import React from 'react'
import '../styles/loader.css'

const Loader = props => {

    const hide = props.loaderHide

    return (
        <div className={`loader ${hide}`}></div>
    )
}

export default Loader