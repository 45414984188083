import React from "react"
import { Modal } from "react-bootstrap"
import '../styles/thankyou.css'
import { getCurrentUser } from "../utils/auth"

const Thankyou = props => {
    
    const { LoginId, StudentName } = getCurrentUser();

    const txnId = props.txnId;
    const batch = props.batch;

    return (
        <Modal id="thank-you" show={true} animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
      <Modal.Body>
        <div className="payment-details">
            <div className="paymentDetails">
                <div className="text-center">
                    <div className="payment-success-p">Payment Successful</div>
                    <div className="img-wrap text-center">
                        <img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/checked.png" alt="Payment Success" />
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Receipt ID:</p>
                        <p><b>CMP{txnId}</b></p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Name:</p>
                        <p>{StudentName}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Email:</p>
                        <p>{LoginId}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Batch:</p>
                        <p>{batch}</p>
                    </div>
                </div>
                <div className="text-center pt-4">
                    <a className="btn btn-primary" href="/app/profile">Go to Profile</a>
                </div>
            </div>
        </div>
      </Modal.Body>
        </Modal>
    )
}

export default Thankyou