import React, {useState, useEffect} from "react"
import { getCurrentUser, logout } from "../utils/auth"
import {Button, Form} from 'react-bootstrap'
import '../styles/account.css'
import Loader from '../components/Loader'
import Thankyou from '../components/Thankyou'
import {navigate,Link} from 'gatsby'
import {loadScript} from '../utils/Helper'


const Profile = () => {

      const { token, LoginId, StudentName } = getCurrentUser()

      const [loaderHide, setLoaderhide] = useState(false);

      const [profile, setProfile] = useState(
        {
          isProfile: true,
          isCourse: false,
          isBatch: false,
          userData: null
        }
      );

      useEffect( () => {
        fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/get-user', {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
        }).then(function(response) {
            return response.json();
        }).then(function(res) {
            if (res.success) {
              setProfile((prevState) => {
                return { ...prevState, userData: res.data.Item};
               });
               setLoaderhide(true)
            }
        }); 
    }, []
    )
  

  const enableTab = current => {
    
    let profile = false;
    let course = false;
    let batch = false;

    switch (current)
    {
        case "profile": 
        profile = true;
        break;
        case "course": 
        course = true;
        break;
        case "batch": 
        batch = true;
        break;
       
        default:
        break;  
    }

    setProfile((prevState) => {
      return { ...prevState, isProfile: profile, isCourse: course, isBatch: batch };
     });

  }

  const updateUserData = event => {
    event.preventDefault()
  }
  
  const [ success, setSuccess ] = useState({
    show: false,
    txnId: null
  })

  const [batchDetails, setBatchDetails] = useState({
    name: null
  })

async function displayRazorpay (data,prevOpts) {

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if(!res) {
        alert('Razorpay SDK failed to load');
        return
    }

    const liveKey='rzp_live_6PTIN3YzEqsjWH';
    // const testKey='rzp_test_uGoq5ABJztRAhk';

    const options = {
        key: liveKey,
        currency: data.currency,
        amount: data.amount,
        order_id: data.orderId,
        name: batchDetails && batchDetails.name,
        image: "https://www.crampete.com/images/logo.svg",
        handler: function (response){

          const opts = {
              TxnId: data.txnId,
              PaymentId: response.razorpay_payment_id,
              OrderId: response.razorpay_order_id,
              IsFirstEMI: false,
              IsEMI: true,
              InstallmentId: prevOpts.InstallmentId,
              UserInstallmentId: prevOpts.UserInstallmentId,
              BatchId: prevOpts.BatchId
          }
        
            fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll', {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(opts)
            }).then(function(response) {
            return response.json();
            }).then(function(res) {

                if(res.success) {
                    setSuccess({
                        show: true,
                        txnId: res.data.txnId
                    })
                }

            });

        },
        prefill: {
            name: StudentName,
            email: LoginId,
        },
    };
    const paymentObject = new window.Razorpay(options);
    setLoaderhide(true);
    paymentObject.open();
}

  const handleEMIPay = (id,batchid,installmentid) => {

    setLoaderhide(false);

    let batchname = profile.userData.Batches.filter( i => i.Id == batchid)[0].Name
    
    setBatchDetails({
      name: batchname
    })

    const opts = {
      BatchId: batchid,
      IsFirstEMI: false,
      IsEMI: true,
      InstallmentId: installmentid,
      UserInstallmentId: id
    }

    let query = Object.keys(opts)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(opts[k]))
             .join('&');

    fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/enroll?'+query, {
    method: 'get',
    headers: {
        "Content-Type": "application/json",
        "Authorization": token
    }
    }).then(function(response) {
    return response.json();
    }).then(function(res) {
        if(res.success) {
            return displayRazorpay(res.data,opts)
        }
    });
  }

  const handlePaymentDetails = (batchid) => {
    let element = document.getElementById(batchid);
    element.classList.toggle("d-block");
  }

   return ( 
         <section className="accountSectionOuter">
           <Loader loaderHide={loaderHide} />
           <div className="container">
            <div className="row"> 
            <div className="col-md-3">
            <div className="accSideBar">
                <div className="accountOuter">
                    <img src="../../images/profile_img.png" alt=""/>
                    <h4>{profile.userData && profile.userData.StudentName}</h4>
                    <h6>{profile.userData && profile.userData.LoginId}</h6>
                    <Button onClick=
                    {event => {
                    event.preventDefault()
                    logout(() => navigate(`/app/login`))
                    }}
                    className="btn btn-primary">Sign Out</Button>
                    
                </div> 
                <div className="profileOuter">
                   <h6>Profile</h6>
                   <ul className="profileMemnu">
                     <li><Button onClick={() => enableTab('profile')} className={(profile.isProfile)? 'active' : '' } to="javascript:void(0);"> My Profile</Button></li>
                     {/* <li><Button onClick={() =>enableTab('course')} className={(profile.isCourse)? 'active' : '' } to="javascript:void(0);"> Courses</Button></li> */}
                     <li><Button onClick={() => enableTab('batch')} className={(profile.isBatch)? 'active' : '' } to="javascript:void(0);"> Batches</Button></li>
                   </ul>
                </div>  
             </div>
            </div>
            <div className="col-md-9">
               <div className="accContentOuter" style={(profile.isProfile)? {display: 'block'} : {display: 'none'}}>
                  <h3>My Profile</h3>
                  <p>This information will be displayed publicly on <span>your profile</span> and in Crampete</p>

                <Form id="profile-form" method="post" onSubmit={updateUserData}>
                
                <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" value={profile.userData && profile.userData.StudentName} />
                <Form.Text className="text-danger"></Form.Text>
                </Form.Group>
                
                <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" readOnly value={profile.userData && profile.userData.EmailId}/>
                <Form.Text className="text-danger"></Form.Text>
                </Form.Group>
                
                <Form.Group controlId="mobile">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" name="mobile" value={profile.userData && profile.userData.MobileNumber}/>
                <Form.Text className="text-danger"></Form.Text>
                </Form.Group>

                
                <div className="text-right">
                {/* <Button variant="primary" type="submit">
                  Save Profile Details
                </Button> */}
                </div>
                </Form>
               </div> 

               <div className="accContentOuter" style={(profile.isCourse)? {display: 'block'} : {display: 'none'}}>
                  <h3>My Courses</h3>
                  <p>Here you can see the <span>courses</span> that enrolled for Crampete</p>

                  <ul className="batchListMenu">
                    {/* {
                      profile.userData && profile.userData.Courses.map(item => {
                        return <li><Button data-id={item.Id}>{item.Title}</Button></li>
                      })
                    } */}
                  </ul>
                </div>   

               <div className="accContentOuter" style={(profile.isBatch)? {display: 'block'} : {display: 'none'}}>
                  <h3>My Batches</h3>
                  <p>Here you can see the <span>batches</span> that enrolled for Crampete</p>

                  <div className="row">
                    {profile.userData &&
                      profile.userData.Batches.length > 0 ? profile.userData.Batches.map(item => {
                        return (
                            <div className="col-md-12">
                            <div className="batchDetails">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="batchImage">
                                  <img src={ item.ThumbnailURL ? item.ThumbnailURL : "https://placeimg.com/300/150/any" } alt={ item.Name ? item.Name : 'Crampete' } />
                                </div>
                                <div className="batch-details">
                                  <h4>{item.Name}</h4>
                                  <small className="text-muted">Courses: {(item.Courses.length == 0 ? 'No Courses Found' : item.Courses.map(course => { return course.Title }).join(", "))}</small>
                                  <small className="text-muted">
                                    Duration: {item.Duration}
                                  </small>
                                  <small className="text-muted">
                                    Batch Ends On: {item.EndDate}
                                  </small>
                                  { item.UserInstallments.length === 0 &&
                                  <React.Fragment>
                                  <small className="text-muted">
                                    Amount: {parseInt(item.ActualPrice) - parseInt(item.DiscountedPrice)}
                                  </small>
                                  <small className="text-muted">
                                    Payment Status: <button disabled className="btn-success">Paid</button>
                                  </small>
                                  </React.Fragment>
                                  }
                                </div>
                                <div className="batch-cta">
                                  <Button className="mb-3">Join Live Webinar</Button>
                                  { item.UserInstallments.length > 0 &&
                                  <Button onClick={ () => handlePaymentDetails(item.Id)}>View Payment Details</Button> }
                                </div>
                              </div>

                              <div className="batchPaymentDetails" id={item.Id}>
                                <table className="table table-bordered table-responsive">
                                  <tr>
                                    <th>EMI.No</th>
                                    <th>Due Date</th>
                                    <th>Due Amount</th>
                                    <th>Trasaction Date &amp; Id</th>
                                    <th>Status</th>
                                  </tr>
                                  {item.UserInstallments && item.UserInstallments.length ? item.UserInstallments.sort((a, b) => a.OrderNumber - b.OrderNumber).map((installment) => {
                                    return (
                                      <tr>
                                        <td>{installment.OrderNumber}</td>
                                        <td>{installment.EMIDate}</td>
                                        <td>{installment.RemainingBalance}</td>
                                        <td>{installment.IsPaid ? `${installment.UpdatedDateTime} | ${installment.TransactionId}` : '-'}</td>
                                        <td>{installment.IsPaid ? <button disabled className="btn-success">Paid</button> : <button className="btn-primary" onClick={ () => handleEMIPay(installment.Id,installment.BatchId,installment.InstallmentId)}>Pay Now</button>}</td>
                                      </tr>
                                    )
                                  }) : ''}
                                </table>
                              </div>
                            </div>

                            </div>
                        )
                      }) : <div className="col-md-12">
                              <p>No batches found!</p>
                              <p>Click <Link to='/batch-calendar/'>here</Link> to view available batches</p>
                            </div>
                    }
                  </div>
                </div>   
            </div> 
          </div>
           </div>
           { success.show && <Thankyou txnId={success.txnId} batch={batchDetails && batchDetails.name} /> }
         </section>
   )
}

export default Profile