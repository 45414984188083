import React, { useState } from "react"
import { Link } from "gatsby"
import { Button, Form, Alert } from "react-bootstrap"
import '../styles/login.css'

const Signup = props => {

  const [formData, setFormData] = useState(
    {
      fields: {},
      errors: {},
      success: '',
      submitState: false
    }
  )

  const handleValidation = () => {
    let fields = formData.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Please enter your name";
    }

    //password
    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = "Please enter your password";
    }
    else if(fields["password"].length <= 6)
    {
      formIsValid = false;
      errors["password"] = "Minimum 6 characters"; 
    }

    //Mobile
    if(!fields["mobile"]){
    formIsValid = false;
    errors["mobile"] = "Please enter your mobile number";
    }
    else if( fields["mobile"].length < 7 )// fields["mobile"].length !== 10
    {
      formIsValid = false;
      errors["mobile"] = "Please enter valid mobile number";
    }

    //Email
    if(!fields["email"]){
    formIsValid = false;
    errors["email"] = "Please enter your email";
    }

    if(typeof fields["email"] !== "undefined"){
    let lastAtPos = fields["email"].lastIndexOf('@');
    let lastDotPos = fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
      }
    }

    if(!fields["country"]) {
      formIsValid = false;
      errors["country"] = "Please select your country";
    }

    setFormData((prevState) => {
      return { ...prevState, errors: errors };
    });
    return formIsValid;
  }

  const handleChange = (field, e) => {  
    let fields = formData.fields;
    fields[field] = e.target.value;

    setFormData((prevState) => {
      return { ...prevState, fields: fields };
     });
   }

  const handleSubmit = async event => {
    event.preventDefault();
    handleValidation()

    if(handleValidation()){
  
      setFormData((prevState) => {
        return { ...prevState, submitState: true };
       });

      document.getElementById("signup-submit").innerHTML = 'Please wait...';
      
      const data = {
        StudentName: formData.fields["name"],
        EmailId: formData.fields["email"],
        MobileNumber: formData.fields["mobile"],
        Password: formData.fields["password"],
        Country: formData.fields["country"],
        State: formData.fields["state"],
        ZipCode: formData.fields["zip"],
        Url: window?.location.origin
      }

      fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/sign-up', {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
        }).then(function(response) {
          return response.json();
        }).then(function(data) {

          if(data.success) {

            setFormData((prevState) => {
              return { ...prevState, success: true };
             });

            document.getElementById("signup-submit").innerHTML = 'Sign Up';
              
          } else {
            
            document.getElementById("error-message").innerHTML = data.message;
            setFormData((prevState) => {
              return { ...prevState, success: false };
             });
            document.getElementById("signup-submit").innerHTML = 'Sign Up';
          }

          setTimeout(
            () => setFormData((prevState) => {
              return { ...prevState, submitState: false, fields: {},success: false  };
             }),
            10000
          )
          document.getElementById("signup-form").reset()
          
        });

    }

  }

  const rspSuccess = (
    <Alert 
      className={`${formData.success === true ? "d-block" : "d-none"}`}
      variant="success"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      “Thank you, your sign up was successful. Please verify your email by clicking the link we delivered to your inbox!”
    </Alert>
  )
  const rspFail = (
    <Alert
      id="error-message"
      className={`${formData.success === false ? "d-block" : "d-none"}`}
      variant="danger"
      style={{ fontSize: "14px", textAlign: "center" }}
    >
      Oops, something went wrong. Please try again later!
    </Alert>
  )
  const submitMsg = formData.success ? rspSuccess : rspFail

    return (
            <section>
                <div className="container">
                    <div className="formWrapper">
                        <div className="text-center mb-4">
                            <h3>Sign Up &amp; Start Learning!</h3>
                        </div>
                        <Form id="signup-form" method="post" onSubmit= {handleSubmit} >
                            {formData.submitState ? submitMsg : ""}
                            <Form.Group controlId="signupName">
                                <Form.Control type="text" name="name"  placeholder="Full Name" onChange={handleChange.bind(this, "name")} />
                                <Form.Text className="text-danger">{formData.errors["name"]}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="signupEmail">
                                <Form.Control type="email" name="email" placeholder="Email" onChange={handleChange.bind(this, "email")} />
                                <Form.Text className="text-danger">{formData.errors["email"]}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="mobile">
                                <Form.Control type="number" placeholder="Mobile" name="mobile" onChange={handleChange.bind(this, "mobile")} />
                                <Form.Text className="text-danger">{formData.errors["mobile"]}</Form.Text>
                                </Form.Group>
                            <Form.Group controlId="signupPassword">
                                <Form.Control type="password" name="password" placeholder="Password" onChange={handleChange.bind(this, "password")} />
                                <Form.Text className="text-danger">{formData.errors["password"]}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="signupCountry">
                              <Form.Control as="select" name="country" onChange={handleChange.bind(this, "country")}>
                              <option value={null} disabled selected>Select Country</option>
                                <option value="India">India</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Other">Other</option>
                              </Form.Control>
                              <Form.Text className="text-danger">{formData.errors["country"]}</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="signupState">
                                <Form.Control type="text" name="state" placeholder="State" onChange={handleChange.bind(this, "state")} />
                            </Form.Group>
                            <Form.Group controlId="signupZip">
                                <Form.Control type="number" placeholder="Zip Code" name="zip" onChange={handleChange.bind(this, "zip")} />
                                </Form.Group>
                            <Form.Group className="text-center">
                                <Button type="submit" id="signup-submit" name="submit" className="btn btn-primary d-block w-100">
                                Sign Up
                                </Button>
                            </Form.Group>
                        </Form>
                        <div className="text-center">
                            <small>By signing up, you agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</small>
                        </div>
                        <div className="text-center mt-4">
                            Already have an account? <Link to="/app/login">Log In</Link>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default Signup